import {
    QUESTIONS_SUBMIT,
    QUESTIONS_SUBMIT_SUCCESS,
    QUESTIONS_SUBMIT_FAILURE
} from './types';

export const questionSubmit = payload => ({
    type: QUESTIONS_SUBMIT,
    payload
});

export const questionSubmitSuccess = payload => ({
    type: QUESTIONS_SUBMIT_SUCCESS,
    payload
});

export const questionSubmitFailure = payload => ({
    type: QUESTIONS_SUBMIT_FAILURE,
    payload
});
