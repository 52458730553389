import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

const IndustriesCard = ({image, title, subTitle, isFullMode, linkTo, comingSoon, width, height}) => {



    const history = useHistory()

    return (
        <>
            <div onClick={() => history.push(linkTo)} className={`industries-card-main col-12 ${isFullMode ? 'col-12' : 'col-md-4'} mb-5`}>
                <div className="industry-card-img-main">
                    <div className="industry-card-img">
                        <img src={image} className="img-fluid w-100" height={height} width={width} alt={title}/>
                    </div>
                    <div className="industry-card-img-content">
                        <h4>{title}</h4>
                        <p>{subTitle}</p>
                    </div>
                </div>
                {
                    comingSoon ?
                        (<div className="coming-soon-main"><h2 className="text-white f-w-900">Coming Soon</h2></div>)
                        :
                        ''
                }
            </div>
        </>
    );
};

IndustriesCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    isFullMode: PropTypes.bool
};

export default memo(IndustriesCard);
