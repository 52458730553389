import React from 'react';
import PropTypes from 'prop-types';

const BlogCard = ({img, title, name, date}) => {
    return (
        <div className="col-12 col-md-4 mb-4">
            <div className="blog-card p-3">
                <div className="blog-card-img text-center">
                    <img src={img} className="img-fluid w-100" alt='blog'/>
                </div>
                <div className="blog-card-content mt-3 text-md-left text-center">
                    <p className="text-primary">{title}</p>
                    {(name && date) &&
                        <span className="text-secondary">
                            By
                            <span className="text-primary ml-2">{name}</span>
                            <span className="dot"/>
                            {date}
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};

BlogCard.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string
};

export default BlogCard;
