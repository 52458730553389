import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {CONTACT_US_FORM} from "../actions/types";
import {
    submitContactUsFormSuccess,
    submitContactUsFormFailure,
} from "../actions";
import API from '../../utils/api';
import {decryptor, parseJwt} from "../../utils/helper";

function* contactUsSubmitRequest(action) {
    try {
        const res = yield API.post(`${process.env.REACT_APP_ENVIRONMENT_TYPE}_career/submitcontactus`, { params: action.payload.data });
        if(res.data.code === 400) {
            yield put(submitContactUsFormFailure());
            yield toast(res?.data?.error?.message);
        } else {
            const parseJwtToken = yield call(parseJwt, res?.data?.content);
            const decrypt = yield call(decryptor, parseJwtToken?.params);
            const decryptJsonParse = JSON.parse(decrypt);
            if(decryptJsonParse.code === 200) {
                yield put(submitContactUsFormSuccess());
                yield call(action.payload.callback)
                yield toast('Thank you for submit your application. we will get back to you shortly');
            }
        }
    } catch (e) {
        yield put(submitContactUsFormFailure());
    }
}

export function* watchContactUsAPI() {
    yield takeEvery(CONTACT_US_FORM, contactUsSubmitRequest)
}

export default function* rootSaga() {
    yield all([
        watchContactUsAPI()
    ])
}
