// ================ Get All Position ===========

export const GET_ALL_POSITION = 'GET_ALL_POSITION';
export const GET_ALL_POSITION_SUCCESS = 'GET_ALL_POSITION_SUCCESS';
export const GET_ALL_POSITION_FAILURE = 'GET_ALL_POSITION_FAILURE';

// ========= Get Position By Id =================

export const GET_POSITION_BY_ID = 'GET_POSITION_BY_ID';
export const GET_POSITION_BY_ID_SUCCESS = 'GET_POSITION_BY_ID_SUCCESS';
export const GET_POSITION_BY_ID_FAILURE = 'GET_POSITION_BY_ID_FAILURE';

// ============ Submit Application ===============

export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_APPLICATION_FAILURE = 'SUBMIT_APPLICATION_FAILURE';

// ============ Question =========================

export const QUESTIONS_SUBMIT = 'QUESTIONS_SUBMIT';
export const QUESTIONS_SUBMIT_SUCCESS = 'QUESTIONS_SUBMIT_SUCCESS';
export const QUESTIONS_SUBMIT_FAILURE = 'QUESTIONS_SUBMIT_FAILURE';

// ================== Get Presigned URL ==========================

export const GET_PRESIGNED_URL = 'GET_PRESIGNED_URL';
export const GET_PRESIGNED_URL_SUCCESS = 'GET_PRESIGNED_URL_SUCCESS';
export const GET_PRESIGNED_URL_FAILURE = 'GET_PRESIGNED_URL_FAILURE';

// ==================== Contact Us ====================================

export const CONTACT_US_FORM = 'CONTACT_US_FORM';
export const CONTACT_US_FORM_SUCCESS = 'CONTACT_US_FORM_SUCCESS';
export const CONTACT_US_FORM_FAILURE = 'CONTACT_US_FORM_FAILURE';
