import React, {useState} from 'react';
import PropTypes from 'prop-types';

import RightArrow from '../../assets/images/rightArray.svg';

const JobCard = ({jobName, location, handleClick}) => {

    const [isHovered, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
    }

    return (
        <div
            className={`card mb-4 job-card-main ${isHovered ? 'animated pulse' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <div className="card-body d-flex align-items-center justify-content-between px-0 px-md-4">
                <div className="d-inline-block">
                    <span className="f-20 f-lat-bol text-dark-secondary">{jobName}</span>
                </div>
                <div className="d-inline-block right-side">
                    <div className="d-flex align-items-center">
                        <span className="f-18 f-lat-reg text-dark-secondary text-capitalize">{location}</span>
                        <img src={RightArrow} className="img-fluid ml-3" alt="RightArrow"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

JobCard.propTypes = {
    jobName: PropTypes.string,
    location: PropTypes.string,
    handleClick: PropTypes.func
};

export default JobCard;
