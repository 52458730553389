import {
    GET_ALL_POSITION,
    GET_ALL_POSITION_SUCCESS,
    GET_ALL_POSITION_FAILURE,

    GET_POSITION_BY_ID,
    GET_POSITION_BY_ID_SUCCESS,
    GET_POSITION_BY_ID_FAILURE,

    SUBMIT_APPLICATION,
    SUBMIT_APPLICATION_SUCCESS,
    SUBMIT_APPLICATION_FAILURE,

    GET_PRESIGNED_URL,
    GET_PRESIGNED_URL_SUCCESS,
    GET_PRESIGNED_URL_FAILURE
} from './types';

export const getAllPosition = payload => ({
    type: GET_ALL_POSITION,
    payload
});

export const getAllPositionSuccess = payload => ({
    type: GET_ALL_POSITION_SUCCESS,
    payload
});

export const getAllPositionFailure = payload => ({
    type: GET_ALL_POSITION_FAILURE,
    payload
});

export const getPositionById = payload => ({
    type: GET_POSITION_BY_ID,
    payload
});

export const getPositionByIdSuccess = payload => ({
    type: GET_POSITION_BY_ID_SUCCESS,
    payload
});

export const getPositionByIdFailure = payload => ({
    type: GET_POSITION_BY_ID_FAILURE,
    payload
});

export const submitApplication = payload => ({
    type: SUBMIT_APPLICATION,
    payload
});

export const submitApplicationSuccess = () => ({
   type: SUBMIT_APPLICATION_SUCCESS
});

export const submitApplicationFailure = () => ({
    type: SUBMIT_APPLICATION_FAILURE
});

export const getPresignedUrl = (payload) => ({
    type: GET_PRESIGNED_URL,
    payload
});

export const getPresignedUrlSuccess = payload => ({
   type: GET_PRESIGNED_URL_SUCCESS,
   payload
});

export const getPresignedUrlFailure = payload => ({
    type: GET_PRESIGNED_URL_FAILURE,
    payload
});
