import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';

import store from './redux/store';
import Routes from './route';
import './libCss';
import reportWebVitals from './reportWebVitals';
import {setToken} from "./utils/helper";

const App = memo(() => {
    React.useEffect(() => {
        setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImNoYW5uZWwiOiJ3ZWIifQ.eyJpYXQiOjE2MDMwOTI0Njd9.YbL55QDrNyUhPII8SwhOHJ44NvMg9IX7e-GmFhOT71U');
    }, [])
    return (
        <Provider store={store()}>
            <Routes />
            <ToastContainer
                autoClose={3000}
                draggable={false}
                transition={Slide}
                closeButton={false}
                hideProgressBar={true}
                position='top-center'
                toastClassName='toast-notification'
            />
        </Provider>
    )
});

ReactDOM.render(<App/>, document.getElementById('root'));
reportWebVitals();
