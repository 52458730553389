import React, { memo, useState, useEffect } from 'react';
import {Link, withRouter} from "react-router-dom";

import logo from '../../assets/images/logo.svg';
import BlackLogo from '../../assets/images/blcklogo.png';
import connectLabBlockImg from '../../assets/images/home/connect-lab-block-header.png';
import helpGuideBlockImg from '../../assets/images/home/help-guides-header.png';
import featuresBlockImg from '../../assets/images/home/features-header.png';
import askDotBlockImg from '../../assets/images/home/ask-dot-header.svg';
import arrowImg from '../../assets/images/dark-blue-arrow.svg';
import RouteFile from "../../route/RouteFile";
import ResponsiveHeader from "./ResponsiveHeader";

const Header = (props) => {
    const [is404, set404] = useState(true)
    const [isMobSidebar, setMobSidebar] = useState(false)

    useEffect(() => {
        if(props.location.pathname === '/industry') {
            set404(false)
        } else  {
            if (props.location.pathname === '/index.html') {
                window.location.replace('/')
            } else {
                set404(!!RouteFile.find(itm => itm.path === props.location.pathname))
            }
        }
    }, [props.location.pathname])

    return (
        <React.Fragment>
            {(props.location.pathname === '/question' || props.location.pathname === '/success') ?
                <></>
                :
                <section
                    className={`${is404 ? 'bg-primary' : 'bg-white border-bottom'} header-main py-3`}
                    id={
                        (props.location.pathname === '/shawer-case-study' ||
                            props.location.pathname === '/vedanta-case-study' ||
                            props.location.pathname === '/argo-case-study' ||
                            props.location.pathname === '/midigiworld-case-study' ||
                            props.location.pathname === '/iskcon-case-study' ||
                            props.location.pathname === '/help-guide-detail' ||
                            props.location.pathname === '/medstars-case-study') &&
                        "navbar"
                    }
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header-wrapper">
                                <nav className="navbar navbar-expand-lg px-0">
                                    <Link  className="navbar-brand" to=''>
                                        <img src={is404 ? logo : BlackLogo} className="img-fluid" alt="logo"/>
                                    </Link>
                                    <button onClick={() => setMobSidebar(true)} className="navbar-toggler" type="button">
                                        <i className={`${is404 ? 'text-white' : 'text-primary'} fa fa-bars f-40`}
                                           aria-hidden="true"/>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav m-auto navigational-links">
                                            <li className="nav-item" onClick={() => window.location.reload(false)}>
                                                <Link className={`${is404 ? 'text-white' : 'text-primary'} nav-link`}
                                                      to="/product">Our Product</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`${is404 ? 'text-white' : 'text-primary'} nav-link`}
                                                      to="/industry">Industries</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`${is404 ? 'text-white' : 'text-primary'} nav-link`}
                                                      to="/about-us">About</Link>
                                            </li>
                                            <li className="nav-item resource-menu">
                                                {/* <Link className={`${is404 ? 'text-white' : 'text-primary'} nav-link`}>Resources</Link>
                                                <div className="mega-menu-main">
                                                    <div className="row">
                                                        <div className="col-12 row justify-content-around"> */}
                                                            {/*<div className="col-md-3">
                                                                <Link to='blog'>
                                                                    <div>
                                                                        <img src={connectLabBlockImg}
                                                                             className="img-fluid mb-3"
                                                                             alt="connect lab blog"/>
                                                                        <h4 className="f-20 f-lat-bol text-white">Connectlab
                                                                            Blog</h4>
                                                                        <p className="f-14 f-lat-reg text-white">Read our
                                                                            story and journey of how we helped customers
                                                                            stay connected.</p>
                                                                    </div>
                                                                </Link>
                                                            </div>*/}

                                                            {/* <div className="col-md-3">
                                                                <Link to='/help-guide'>
                                                                    <div className="cursor-text">
                                                                        <img src={helpGuideBlockImg}
                                                                             className="img-fluid mb-3" alt="help guides"/>
                                                                        <h4 className="f-20 f-lat-bol text-white">Help
                                                                            Guides</h4>
                                                                        <p className="f-14 f-lat-reg text-white">Read
                                                                            through our solution and how to get started
                                                                            today</p>
                                                                        <Link
                                                                            className="mt-5 text-white f-14 f-lat-reg d-flex align-items-center" to="/help-guide">Read More
                                                                            <img
                                                                                src={arrowImg} className="img-fluid ml-3"
                                                                                alt="read more arrow"/></Link>
                                                                    </div>
                                                                </Link>
                                                            </div> */}

                                                            {/* <div className="col-md-3 cp">
                                                                <div>
                                                                    <img src={featuresBlockImg}
                                                                         className="img-fluid mb-3" alt="features"/>
                                                                    <h4 className="f-20 f-lat-bol text-white">Features
                                                                        101</h4>
                                                                    <p className="f-14 f-lat-reg text-white">Online
                                                                        guides to learn the basics and refine your
                                                                        skills.</p>
                                                                </div>
                                                            </div> */}

                                                            {/*<div className="col-md-3">
                                                                <div>
                                                                    <img src={askDotBlockImg} className="img-fluid mb-3"
                                                                         alt="ask dot"/>
                                                                    <h4 className="f-20 f-lat-bol text-white">Ask
                                                                        Dot</h4>
                                                                    <p className="f-14 f-lat-reg text-white">Have a
                                                                        question and need some additional guidance?</p>
                                                                    <button className="c-btn bg-dark-primary">Ask Dot
                                                                    </button>
                                                                </div>
                                                            </div>*/}
                                                        {/* </div>
                                                    </div>
                                                </div> */}
                                            </li>
                                        </ul>
                                        <Link to={{
                                            pathname: "/question",
                                            state: { selectedIndustry: 'Other' }
                                        }}>
                                            <button className="c-btn">Get Started</button>
                                        </Link>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <ResponsiveHeader isMobSidebar={isMobSidebar} setMobSidebar={setMobSidebar}/>
        </React.Fragment>
    );
};

export default withRouter(memo(Header));
