import {
    CONTACT_US_FORM,
    CONTACT_US_FORM_SUCCESS,
    CONTACT_US_FORM_FAILURE
} from '../actions/types';

const INIT_STATE = {
    loading: false
}

// eslint-disable-next-line
export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case CONTACT_US_FORM:
            return { ...state, loading: true };
        case CONTACT_US_FORM_SUCCESS:
            return { ...state, loading: false };
        case CONTACT_US_FORM_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
}
