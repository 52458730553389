import {
    QUESTIONS_SUBMIT,
    QUESTIONS_SUBMIT_SUCCESS,
    QUESTIONS_SUBMIT_FAILURE
} from '../actions/types'

const INIT_STATE = {
    loading: false
}

// eslint-disable-next-line
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case QUESTIONS_SUBMIT:
            return { ...state, loading: true };
        case QUESTIONS_SUBMIT_SUCCESS:
            return { ...state, loading: false };
        case QUESTIONS_SUBMIT_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
}
