import { all, takeEvery, put, call } from 'redux-saga/effects';

import {QUESTIONS_SUBMIT} from "../actions/types";
import {questionSubmitFailure, questionSubmitSuccess} from "../actions";
import API from '../../utils/api';
import {decryptor, parseJwt} from "../../utils/helper";

function* questionSubmitRequest(action) {
    try {
        const res = yield API.post('dev_questionnaire/submitquestionnaire', {params: action.payload.data});
        if(res.data.code === 400) {
            yield put(questionSubmitFailure(res.data));
        } else {
            const parseJwtToken = yield call(parseJwt, res?.data?.content);
            const decrypt = yield call(decryptor, parseJwtToken?.params);
            const decryptJsonParse = JSON.parse(decrypt);
            if(decryptJsonParse.code === 200) {
                yield put(questionSubmitSuccess(decryptJsonParse));
                yield call(action.payload.callback)
            }
        }
    } catch (e) {
        yield put(questionSubmitFailure(e));
    }
}

export function* watchQuestionSubmitAPI() {
    yield takeEvery(QUESTIONS_SUBMIT, questionSubmitRequest)
}

export default function* rootSaga() {
    yield all([
        watchQuestionSubmitAPI()
    ]);
}
