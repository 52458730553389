import React, { memo } from 'react';

const Loader = () => {
    return (
        <div className="loader">
            <div className="loader-inner">
                <div className="loader-line-wrap-wrap">
                    <div className="loader-line-wrap" />
                </div>
                <div className="loader-line-wrap-wrap">
                    <div className="loader-line-wrap" />
                </div>
                <div className="loader-line-wrap-wrap">
                    <div className="loader-line-wrap" />
                </div>
            </div>
        </div>
    );
};

export default memo(Loader);
