import aesjs from "aes-js"

export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => localStorage.setItem('token', token)

const DefaultKey = 'MyE9JzG8P4lynEIwhE8tkpHORcTfkyEK';

export const encryptor = (input, pref, s) => {
    try {
        const key = DefaultKey.trim()
        const value = input.trim()
        const keyBuffer = aesjs.utils.utf8.toBytes(key)
        const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(value))
        /* eslint new-cap: "off" */
        const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer)
        const encryptedBytes = escEcb.encrypt(inputBuffer)
        const encryptedData = Buffer.from(encryptedBytes).toString("base64")
        if ((pref, s)) {
            const tPref = pref.trim()
            const tS = s.trim()
            return `${tPref}${tS}${encryptedData}`
        }
        return encryptedData
    } catch (error) {
        return {
            message: "Something went wrong while encrypting data",
            error: true,
            code: "401",
        }
    }
}

export const decryptor = (input, s) => {
    try {
        const key = DefaultKey.trim()
        let data
        if (s) {
            const _s = s.trim()
            data = input.split(_s)[1].trim()
        } else {
            data = input.trim()
        }
        /* eslint new-cap: "off" */
        const keyBuffer = aesjs.utils.utf8.toBytes(key)
        const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer)
        const buf = Buffer.from(data, "base64")
        let decryptedBytes = escEcb.decrypt(buf)
        decryptedBytes = aesjs.padding.pkcs7.strip(decryptedBytes)
        return aesjs.utils.utf8.fromBytes(decryptedBytes)
    } catch (error) {
        return {
            message: "Something went wrong while decrypting data",
            error: true,
            code: "401",
        }
    }
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export const checker = (arr) => arr.every(v => v === true);
