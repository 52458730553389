import { combineReducers } from 'redux';

import Careers from './careersReducer';
import Question from './questionReducer';
import ContactUs from './contactUsReducer';

const appReducer = combineReducers({
    Careers,
    Question,
    ContactUs
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
