import {
    GET_ALL_POSITION,
    GET_ALL_POSITION_SUCCESS,
    GET_ALL_POSITION_FAILURE,

    GET_POSITION_BY_ID,
    GET_POSITION_BY_ID_SUCCESS,
    GET_POSITION_BY_ID_FAILURE,

    SUBMIT_APPLICATION,
    SUBMIT_APPLICATION_SUCCESS,
    SUBMIT_APPLICATION_FAILURE,

    GET_PRESIGNED_URL,
    GET_PRESIGNED_URL_SUCCESS,
    GET_PRESIGNED_URL_FAILURE
} from '../actions/types';

const INIT_STATE = {
    loading: false,
    allPosition: {},
    positionById: {},
    presignedUrl: {}
}

// eslint-disable-next-line
export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_POSITION:
            return { ...state, loading: true };
        case GET_ALL_POSITION_SUCCESS:
            return { ...state, loading: false, allPosition: action.payload };
        case GET_ALL_POSITION_FAILURE:
            return { ...state, loading: false, allPosition: action.payload };
        case GET_POSITION_BY_ID:
            return { ...state, loading: true };
        case GET_POSITION_BY_ID_SUCCESS:
            return { ...state, loading: false, positionById: action.payload };
        case GET_POSITION_BY_ID_FAILURE:
            return { ...state, loading: false, positionById: action.payload };
        case SUBMIT_APPLICATION:
            return { ...state, loading: true };
        case SUBMIT_APPLICATION_SUCCESS:
            return { ...state, loading: false };
        case SUBMIT_APPLICATION_FAILURE:
            return { ...state, loading: false };
        case GET_PRESIGNED_URL:
            return { ...state, loading: true };
        case GET_PRESIGNED_URL_SUCCESS:
            return { ...state, loading: false, presignedUrl: action.payload };
        case GET_PRESIGNED_URL_FAILURE:
            return { ...state, loading: false, presignedUrl: action.payload };
        default:
            return state;
    }
}
