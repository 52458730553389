import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {GET_ALL_POSITION, GET_POSITION_BY_ID, GET_PRESIGNED_URL, SUBMIT_APPLICATION} from "../actions/types";
import {
    getAllPositionSuccess,
    getAllPositionFailure,
    getPositionByIdFailure,
    getPositionByIdSuccess,
    getPresignedUrlSuccess,
    getPresignedUrlFailure,
    submitApplicationSuccess,
    submitApplicationFailure
} from "../actions";
import API from '../../utils/api';
import {decryptor, parseJwt} from "../../utils/helper";

function* getAllPositionRequest(action) {
    try {
        const res = yield API.post(`${process.env.REACT_APP_ENVIRONMENT_TYPE}_career/getallpositions`, {params: action.payload});
        if(res.data.code === 400) {
            yield put(getAllPositionFailure(res.data));
        } else {
            const parseJwtToken = yield call(parseJwt, res?.data?.content);
            const decrypt = yield call(decryptor, parseJwtToken?.params);
            const decryptJsonParse = JSON.parse(decrypt);
            if(decryptJsonParse.code === 200) {
                yield put(getAllPositionSuccess(decryptJsonParse));
            }
        }
    } catch (e) {
        yield put(getAllPositionFailure(e));
    }
}

function* getAllPositionByIdRequest(action) {
    try {
        const res = yield API.post(`${process.env.REACT_APP_ENVIRONMENT_TYPE}_career/getpositionbyid`, {params: action.payload});
        if(res.data.code === 400) {
            yield put(getPositionByIdFailure(res.data));
        } else {
            const parseJwtToken = yield call(parseJwt, res?.data?.content);
            const decrypt = yield call(decryptor, parseJwtToken?.params);
            const decryptJsonParse = JSON.parse(decrypt);
            if(decryptJsonParse.code === 200) {
                yield put(getPositionByIdSuccess(decryptJsonParse));
            }
        }
    } catch (e) {
        yield put(getPositionByIdFailure(e));
    }
}

function* getPresignedUrlRequest(action) {
    try {
        const res = yield API.post(`${process.env.REACT_APP_ENVIRONMENT_TYPE}_common/getsignedputobjecturl`, {params: action.payload.encryptedData})
        if(res.data.code === 400) {
            yield put(getPresignedUrlFailure(res.data));
        } else {
            const parseJwtToken = yield call(parseJwt, res?.data?.content);
            const decrypt = yield call(decryptor, parseJwtToken?.params);
            const decryptJsonParse = JSON.parse(decrypt);
            if(decryptJsonParse.code === 200) {
                yield put(getPresignedUrlSuccess(decryptJsonParse));
                yield call(action.payload.callback)
            }
        }
    } catch (e) {
        yield put(getPresignedUrlFailure(e));
    }
}

function* submitApplicationRequest(action) {
    try {
        const res = yield API.post(`${process.env.REACT_APP_ENVIRONMENT_TYPE}_career/submitapplication`, {params: action.payload})
        if(res.data.code === 400) {
            yield put(submitApplicationFailure());
            yield toast(res?.data?.error?.message)
        } else {
            const parseJwtToken = yield call(parseJwt, res?.data?.content);
            const decrypt = yield call(decryptor, parseJwtToken?.params);
            const decryptJsonParse = JSON.parse(decrypt);
            if(decryptJsonParse.code === 200) {
                yield put(submitApplicationSuccess());
                yield call(action.payload.callback);
                // yield toast('Thank you for submit your application. we will get back to you shortly');
            }
        }
    } catch (e) {
        yield put(submitApplicationFailure());
    }
}

export function* watchGetAllPositionAPI() {
    yield takeEvery(GET_ALL_POSITION, getAllPositionRequest)
}

export function* watchGetPositionById() {
    yield takeEvery(GET_POSITION_BY_ID, getAllPositionByIdRequest)
}

export function* watchGetPresignedUrlAPI() {
    yield takeEvery(GET_PRESIGNED_URL, getPresignedUrlRequest)
}

export function* watchSubmitApplicationAPI() {
    yield takeEvery(SUBMIT_APPLICATION, submitApplicationRequest)
}

export default function* rootSaga() {
    yield all([
        watchGetAllPositionAPI(),
        watchGetPositionById(),
        watchGetPresignedUrlAPI(),
        watchSubmitApplicationAPI()
    ]);
}

