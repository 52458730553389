import React from 'react';
import PropTypes from 'prop-types';

const Input = ({type, placeholder, value, disabled, handleChange, name}) => {
    return (
        <div className='input-with-lable'>
            <input
                type={type}
                className="form-control"
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                name={name}
            />
        </div>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func,
    name: PropTypes.string
};

export default Input;
