import React, {useState} from 'react';
import PropTypes from 'prop-types';

const QuestionCard = ({data, Img, Text, Title, isActive, setActiveInactive, ActiveImg}) => {
    const [isHovered, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
    }

    return (
        <div
            className={`${(isHovered || isActive) ? 'active-question-card animated pulse' : 'question-card'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => setActiveInactive(data)}
        >
            <div className="media">
                <img className="align-self-center mr-4" src={(isHovered || isActive) ? ActiveImg : Img} alt={Text} />
                <div className="media-body align-self-center">
                    <span className="mb-0">{Title}</span>
                    <p className="mb-0">{Text}</p>
                </div>
            </div>
        </div>
    );
};

QuestionCard.propTypes = {
    Text: PropTypes.string,
    Img: PropTypes.object,
    Title: PropTypes.string,
    isActive: PropTypes.bool,
    setActiveInactive: PropTypes.func,
    data: PropTypes.object,
    ActiveImg: PropTypes.object
};

export default QuestionCard;
