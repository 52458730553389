import {
    CONTACT_US_FORM,
    CONTACT_US_FORM_SUCCESS,
    CONTACT_US_FORM_FAILURE,
} from './types';

export const submitContactUsForm = (payload) => ({
    type: CONTACT_US_FORM,
    payload
});

export const submitContactUsFormSuccess = () => ({
    type: CONTACT_US_FORM_SUCCESS
});

export const submitContactUsFormFailure = () => ({
    type: CONTACT_US_FORM_FAILURE
});
