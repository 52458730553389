import { all } from 'redux-saga/effects';

import Career from './careersSaga';
import Question from './questionSaga';
import ContactUs from './contactUsSaga';

export default function* rootSaga() {
    yield all([
        Career(),
        Question(),
        ContactUs()
    ]);
}
