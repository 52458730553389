import { lazy } from 'react';

const Home = lazy(() => import('../container/Home'));
const Blog = lazy(() => import('../container/Blog'));
const HelpGuide = lazy(() => import('../container/HelpGuide'));
const HelpGuideDetail = lazy(() => import('../container/HelpGuideDetail'));
const ThankYou = lazy(() => import('../container/ThankYou'));
const CareerDetails = lazy(() => import('../container/CareerDetails'));
const SubmitYourApplication = lazy(() => import('../container/SubmitYourApplication'));
const AboutUs = lazy(() => import('../container/AboutUs'));
const Product = lazy(() => import('../container/Product'));
const ArgoCaseStudy = lazy(() => import('../container/CaseStudy/ArgoCaseStudy'));
const MidigiworldCaseStudy= lazy(() => import('../container/CaseStudy/MidigiworldCaseStudy'));
const Industry = lazy(() => import('../container/Industry'));
const Question = lazy(() => import('../container/QuestionPage'));
const IskconCaseStudy = lazy(() => import('../container/CaseStudy/IskconCaseStudy'));
const ShawerCaseStudy = lazy(() => import('../container/CaseStudy/ShawerCaseStudy'));
const VedantaCaseStudy = lazy(() => import('../container/CaseStudy/VedantaCaseStudy'));
const MentalHealthCaseStudy = lazy(() => import('../container/CaseStudy/MentalHealthCaseStudy'));
const MedstarsCaseStudy = lazy(() => import('../container/CaseStudy/MedstarsCaseStudy'));
const Success = lazy(() => import('../container/Success'));

const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home Page',
        component: Home,
        private: false
    },
    {
        path: '/blog',
        exact: true,
        name: 'Blog Page',
        component: Blog,
        private: false
    },
    {
        path: '/help-guide',
        exact: true,
        name: 'Help Guide Page',
        component: HelpGuide,
        private: false
    },
    {
        path: '/thankyou',
        exact: true,
        name: 'ThankYou Page',
        component: ThankYou,
        private: false
    },
    {
        path: '/career-details-page',
        exact: true,
        name: 'CareerDetails Page',
        component: CareerDetails,
        private: false
    },
    {
        path: '/help-guide-detail',
        exact: true,
        name: 'Help Guide Detail Page',
        component: HelpGuideDetail,
        private: false
    },
    {
        path: '/submit-your-application',
        exact: true,
        name: 'Submit your application Page',
        component: SubmitYourApplication,
        private: false
    },
    {
        path: '/about-us',
        exact: true,
        name: 'AboutUs Page',
        component: AboutUs,
        private: false
    },
    {
        path: '/product',
        exact: true,
        name: 'Product Page',
        component: Product,
        private: false
    },
    {
        path: '/argo-case-study',
        exact: true,
        name: 'Argo Case Study',
        component: ArgoCaseStudy,
        private: false
    },
    {
        path: '/midigiworld-case-study',
        exact: true,
        name: 'Midigiworld Study',
        component: MidigiworldCaseStudy,
        private: false
    },
    {
        path: '/question',
        exact: true,
        name: 'Question',
        component: Question,
        private: false
    },
    {
        path: '/iskcon-case-study',
        exact: true,
        name: 'Iskcon Case Study',
        component: IskconCaseStudy,
        private: false
    },
    {
        path: '/shawer-case-study',
        exact: true,
        name: 'Shawer Case Study',
        component: ShawerCaseStudy,
        private: false
    },
    {
        path: '/vedanta-case-study',
        exact: true,
        name: 'Vedanta Case Study',
        component: VedantaCaseStudy,
        private: false
    },
    {
        path: '/medstars-case-study',
        exact: true,
        name: 'Medstars Case Study',
        component: MedstarsCaseStudy,
        private: false
    },
    {
        path: '/industry',
        exact: true,
        name: 'Industry Page',
        component: Industry,
        private: false
    },
    {
        path: '/success',
        exact: true,
        name: 'Success Page',
        component: Success,
        private: false
    },
    {
        path : '/mental-health-case-study',
        exact : true,
        name : 'Mental Health Case Study',
        component : MentalHealthCaseStudy,
        private : false
    },
]

export default routes;
