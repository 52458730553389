import React from 'react';
import PropTypes from 'prop-types';

const Button = ({children, handleClick, disabled}) => {
    return (
        <button
            className="button-main hvr-bounce-to-right"
            onClick={handleClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
};

Button.propTypes = {
    children: PropTypes.string,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default Button;
