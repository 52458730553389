import React, {memo, useEffect, useState} from 'react';
import {Link, withRouter, useHistory} from 'react-router-dom';

import footerLogo from '../../assets/images/footer-logo.svg';
import facebookImg from '../../assets/images/facebook.svg';
import linkedinImg from '../../assets/images/linkedin.svg';
import twitterImg from '../../assets/images/twitter.svg';
import instagramImg from '../../assets/images/instagram.svg';
import RouteFile from "../../route/RouteFile";

const Footer = (props) => {
    const [isFooterVisible, setFooterVisible] = useState(true)
    const today = new Date();
    const year = today.getFullYear();
    const history = useHistory();
    useEffect(() => {
        if(props.location.pathname === '/question' || props.location.pathname === '/success') {
            setFooterVisible(false)
        } else {
            setFooterVisible(!!RouteFile.find(itm => itm.path === props.location.pathname))
        }
    }, [props.location.pathname])
    
    const handleClick = () => {
        history.push('/')
    }

    return (
        isFooterVisible ?
            <React.Fragment>
                <section className="footer-main py-5">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-4 col-lg-4 pt-3 pr-md-0">
                                <img src={footerLogo} onClick={() => {handleClick()}} style={{cursor:'pointer'}} className="img-fluid" alt="logo"/>
                                <p className="f-16 text-light-white f-lat-reg mt-5">At Connectlab.ai we have some of the most talented engineers, developers, Quality Assurance Engineers, designers, and thought leaders that are enabling businesses online.</p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-6">
                                <div className="row justify-content-md-around p-md-0">
                                    <div className="col-5 col-md-4 footer-links">
                                        <h5>SERVICES</h5>
                                        <ul className="list-unstyled mt-3">
                                            {/* <li className="d-block"><Link to='/blog'>Blog</Link></li> */}
                                            <li className="d-block"><Link to='/product'>FAQ</Link></li>
                                            <li className="d-block"><Link>Support</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-5 col-md-4 footer-links">
                                        <h5>COMPANY</h5>
                                        <ul className="list-unstyled mt-3">
                                            <li className="d-block"><Link to='/about-us'>About</Link></li>
                                            <li className="d-block"><Link>Terms</Link></li>
                                            <li className="d-block"><Link>Privacy Policy</Link></li>
                                            <li className="d-block"><Link to='/about-us'>Careers</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-5 col-md-4 footer-links">
                                        <h5>CONTACT US</h5>
                                        <ul className="list-unstyled mt-3">
                                            <li className="d-block">
                                                <Link>
                                                    <a onClick={ (e) => {
                                                            window.location =  "mailto:info@connectlab.ai";
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        info@connectlab.ai
                                                    </a>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-lg-end mt-3 mt-xl-5">
                            <div className="col-12 col-md-6 col-lg-4 text-center text-md-left">
                                <h3 className="text-white f-16 f-lat-reg">FOLLOW US</h3>
                                <ul className="social-links mt-4">
                                    <li className="d-inline-block">
                                        <a target="_blank" href="https://www.facebook.com/connectlabai">
                                            <img src={facebookImg} className="img-fluid" alt="facebook"/>
                                        </a>
                                    </li>

                                    <li className="d-inline-block">
                                        <a target="_blank" href="https://www.linkedin.com/company/connect-lab-ai/">
                                            <img src={linkedinImg} className="img-fluid" alt="linkedin"/>
                                        </a>
                                    </li>

                                    <li className="d-inline-block">
                                        <a target="_blank" href="https://twitter.com/ConnectLab_ai">
                                            <img src={twitterImg} className="img-fluid" alt="twitter"/>
                                        </a>
                                    </li>

                                    <li className="d-inline-block">
                                        <a target="_blank" href="https://www.instagram.com/connectlab_ai/">
                                            <img src={instagramImg} className="img-fluid" alt="instagram"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="copy-right py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center text-lg-left">
                                <p className="m-0 text-white f-16 f-lat-reg">Privacy Policy <span
                                    className="mx-1 f-20">|</span> Copyright © {year}. Crafted with love. <span
                                    className="mx-1 f-20">|</span> A Jump360 Company</p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment> :
            null
    );
};

export default withRouter(memo(Footer));
