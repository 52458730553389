import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import connectLabBlockImg from "../../assets/images/home/connect-lab-block-header.png";
import helpGuideBlockImg from "../../assets/images/home/help-guides-header.png";
import arrowImg from "../../assets/images/dark-blue-arrow.svg";
import featuresBlockImg from "../../assets/images/home/features-header.png";
import askDotBlockImg from "../../assets/images/home/ask-dot-header.svg";

const ResponsiveHeader = ({isMobSidebar, setMobSidebar}) => {

    const refreshPage = ()=>{
        window.location.reload(false);
        setMobSidebar(false)
    }

    return (
        <React.Fragment>
            <section  className={`${isMobSidebar ? 'responsive-sidebar-header-active' : 'responsive-sidebar-header-inactive'} responsive-sidebar-header py-4 px-4`}>
                <div className="d-flex align-items-center justify-content-between px-0 text-right d-inline-block cp">
                    <div className="d-inline-block">
                        <Link  className="navbar-brand" to=''>
                            <img src={logo} className="img-fluid" alt="logo"/>
                        </Link>
                    </div>
                    <div className="d-inline-block" onClick={() => setMobSidebar(false)}>
                        <i  className="f-34 fa fa-times text-white" aria-hidden="true"/>
                    </div>

                </div>
                <ul className="list-unstyled mobile-menu-list mt-4">
                    <li onClick={refreshPage}><Link to='/product'>Our product</Link></li>
                    <li onClick={() => setMobSidebar(false)}><Link to='/industry'>Industries</Link></li>
                    <li onClick={() => setMobSidebar(false)}><Link to='/about-us'>About</Link></li>
                    <li>Resources
                        <div className="resource-block">
                            <div className="col-12 row justify-content-between mx-auto px-0">
                                {/*<div className="col-6 mb-5">
                                    <Link to='blog' onClick={() => setMobSidebar(false)}>
                                        <div>
                                            <img src={connectLabBlockImg}
                                                 className="img-fluid mb-3"
                                                 alt="connect lab blog"/>
                                            <h4 className="f-20 f-lat-bol text-white">Connectlab
                                                Blog</h4>
                                            <p className="f-14 f-lat-reg text-white">Read our
                                                story and journey of how we helped customers
                                                stay connected.</p>
                                        </div>
                                    </Link>
                                </div>*/}

                                <div className="col-6 mb-5">
                                    <Link to='/help-guide' onClick={() => setMobSidebar(false)}>
                                        <div>
                                            <img src={helpGuideBlockImg}
                                                 className="img-fluid mb-3" alt="help guides"/>
                                            <h4 className="f-20 f-lat-bol text-white">Help
                                                Guides</h4>
                                            <p className="f-14 f-lat-reg text-white">Read
                                                through our solution and how to get started
                                                today</p>
                                            <Link
                                                className="mt-3 text-white f-14 f-lat-reg d-flex align-items-center">Read More
                                                <img
                                                    src={arrowImg} className="img-fluid ml-3"
                                                    alt="read more arrow"/></Link>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-6 mb-5">
                                    <div>
                                        <img src={featuresBlockImg}
                                             className="img-fluid mb-3" alt="features"/>
                                        <h4 className="f-20 f-lat-bol text-white">Features
                                            101</h4>
                                        <p className="f-14 f-lat-reg text-white">Online
                                            guides to learn the basics and refine your
                                            skills.</p>
                                    </div>
                                </div>

                                {/*<div className="col-6 mb-5">
                                    <div>
                                        <img src={askDotBlockImg} className="img-fluid mb-3"
                                             alt="ask dot"/>
                                        <h4 className="f-20 f-lat-bol text-white">Ask
                                            Dot</h4>
                                        <p className="f-14 f-lat-reg text-white">Have a
                                            question and need some additional guidance?</p>
                                        <button className="c-btn bg-dark-primary">Ask Dot
                                        </button>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </li>
                    <li onClick={() => setMobSidebar(false)}>
                        <Link to={{
                            pathname: "/question",
                            state: { selectedIndustry: 'Other' }
                        }}>
                            <button className="c-btn">Get Started</button>
                        </Link>
                    </li>
                </ul>

            </section>
        </React.Fragment>
    );
};

ResponsiveHeader.propTypes = {
    isMobSidebar: PropTypes.bool,
    setMobSidebar: PropTypes.func
};

export default ResponsiveHeader;
