import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import readyImg from "../../assets/images/ready-logo.svg";
import rightArrowImg from "../../assets/images/get-start-arrow.svg";

const ReadyToConnect = () => {
    const [selectedIndustry, setSelectedIndustry] = useState('Healthcare & Wellbeing');
    const history = useHistory();
    return (
        <>
            <div className="col-12 col-md-12 ready-to-connect-wrapper w-100">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-6 text-center text-md-left">
                        <div className="ready-left">
                            <img src={readyImg} className="img-fluid" alt="ready logo"/>
                            <h2 className="my-3 f-36 f-lat-bol text-white">Ready to Connect?</h2>
                            <p className="f-18 f-lat-reg text-white">
                                Tell us a little bit about your industry and your goals so we can start connecting the
                                dots for you. Its as simple as that!
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5">
                        <div className="ready-right p-3 p-md-5">
                            <p className="f-16 f-lat-reg text-white">What industry do you belong to?</p>
                            <select
                                className="ready-custom-select"
                                value={selectedIndustry}
                                onChange={e => setSelectedIndustry(e.target.value)}
                            >
                                <option value="Healthcare & Wellbeing">Healthcare & Wellbeing</option>
                                <option value="Insurance">Insurance</option>
                                <option value="Law">Law</option>
                                <option value="Learning & Development">Learning & Development</option>
                                <option value="Non for Profit & NGOs">Non for Profit & NGOs</option>
                                <option value="Sports & Fitness">Sports & Fitness</option>
                                <option value="Natural Resources">Natural Resources</option>
                                <option value="Professional Services">Professional Services</option>
                                <option value="Financial Services">Financial Services</option>
                                <option value="Other">Other</option>
                            </select>

                            <button className="btn get-start-btn" onClick={() => history.push('/question', {
                                selectedIndustry
                            })}>
                                Get Started <img src={rightArrowImg} className="img-fluid" alt="right arrow"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReadyToConnect;
